import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import isEqual from "lodash.isequal"
import { getSrc } from "gatsby-plugin-image"
import { StoreContext } from "../context/store-context"
import { AddToCart } from "../components/add-to-cart"
import { NumericInput } from "../components/numeric-input"
import { formatPrice } from "../utils/format-price"
import { Seo } from "../components/seo"
import ProductGallery from "../components/product-gallery"
import GiftWrapBtn from "../components/gift-wrap-button"

import {
  container,
  info,
  header,
  giftWrap,
  subheader,
  productImageWrapper,
  noImagePreview,
  priceValue,
  purchaseInfo,
  metaSection,
  productDescription,
  productTitleAndSubtitle,
  backToShop,
  mobileBackToShop,
  fewVariants,
  manyVariants,
} from "../templates/product-page.module.css"

export default function Product({data}) {
  const product = data.shopifyProduct
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    collections,
    metafields,
    description,
    totalInventory,
    images,
    images: [firstImage],
  } = product
  const { client } = React.useContext(StoreContext)
  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? []
        
        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleVariantClick = (value, index) => {
    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const hasVariants = variants.length > 1
  const hasImages = images.length > 0

  const subtitle = metafields.find(field=>field.key==="subtitle") ? metafields.find(field=>field.key==="subtitle").value : "Sweet Pickle Books"
  const metafieldsObj = metafields.reduce((a, v) => ({ ...a, [v.key]: v.value}), {})

  return (
    <Layout containerStyle={"hasDesktopLanding"}>
      {firstImage ? (
        <Seo
          title={title}
          description={description}
          image={getSrc(firstImage.gatsbyImageData)}
        />
      ) : undefined}
      <div className={container}>
      <div className={info}>
        <Link to={`/shop/`} className={backToShop}>← Back to Shop</Link>
        <div className={productTitleAndSubtitle}>
          <h1 className={header}>{title}</h1>
          <p className={subheader}>{subtitle}</p>
        </div>

        <p className={productDescription}>{description}</p>

        <div className={purchaseInfo}>
            <p className={giftWrap}>Gift wrap? <GiftWrapBtn /></p>
            <div>
            {hasVariants &&
                options.map(({ id, name, values }, index) => {
                  return options[0].values.length > 5 ?
                  <div key={id} className={manyVariants}>
                      {values.map((value) => (
                        <button className="hoverableBtn" style={value===variant.title ? {backgroundColor: 'var(--main-color)', color: "#fff"} : {}} onClick={()=>handleVariantClick(value, index)} key={`${name}-${value}`}>
                          {value}
                        </button>
                      ))}
                  </div>
                  :
                  <div key={id} className={fewVariants}>
                      {values.map((value) => (
                        <button className="hoverableBtn" style={value===variant.title ? {backgroundColor: 'var(--main-color)', color: "#fff"} : {}} onClick={()=>handleVariantClick(value, index)} key={`${name}-${value}`}>
                          {value}
                        </button>
                      ))}
                  </div>
                }
              )}
            </div>
            {totalInventory > 1 &&
              <NumericInput
              aria-label="Quantity"
              onIncrement={() => setQuantity((q) => Math.min(q + 1, totalInventory))}
              onDecrement={() => setQuantity((q) => Math.max(1, q - 1))}
              onChange={(event) => setQuantity(event.currentTarget.value)}
              value={quantity}
              labelVis={true}
              min="1"
              max={totalInventory}
            />
            }
          <AddToCart
            variantId={productVariant.storefrontId}
            quantity={quantity}
            available={available}
            collections={collections}
            isUnique={totalInventory <= 1}
          />               
        </div>

        <h2 className={priceValue}>
          <span>{price}</span>
        </h2>

        <div className={metaSection}>
          {metafieldsObj.info_1 && <p>{metafieldsObj.info_1}</p>}
          {metafieldsObj.info_2 && <p>{metafieldsObj.info_2}</p>}
          {metafieldsObj.info_3 && <p>{metafieldsObj.info_3}</p>}
          {metafieldsObj.info_4 && <p>{metafieldsObj.info_4}</p>}
          {/* {collections.some(coll=>coll.handle==="merch") && <p className={modalOpener} onClick={()=>setModalVis(!modalVis)}>Size chart</p>} */}
        </div>
      </div>
      {hasImages && 
            <div className={productImageWrapper}>
              <ProductGallery images={images} available={available} />
            </div>
        }      
        {!hasImages && (
          <span className={noImagePreview}>No Preview image</span>
        )}
        <Link to={`/shop/`} className={mobileBackToShop}>← Back to Shop</Link>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query product {
    shopifyProduct(isGiftCard: {eq: true}) {
              title
              handle
              collections {
                handle
              }
              totalInventory
              metafields {
                key
                value
              }
              description
              productType
              tags
              priceRangeV2 {
                maxVariantPrice {
                  amount
                  currencyCode
                }
                minVariantPrice {
                  amount
                  currencyCode
                }
              }
              storefrontId
              images {
                originalSrc
                altText
                id
                gatsbyImageData(width: 910)
              }
              variants {
                availableForSale
                storefrontId
                title
                price
                selectedOptions {
                  name
                  value
                }
              }
              options {
                name
                values
                id
              }
            }
    }
`
